<template>
  <v-card class="slider-card">
    <stripe-checkout
      v-if="environment == 'development'"
      ref="checkoutRef"
      mode="payment"
      :pk="uatVars.publishableKey"
      :line-items="uatVars.lineItems"
      :success-url="uatVars.successURL"
      :cancel-url="uatVars.cancelURL"
      :clientReferenceId="uid"
      :customerEmail="userEmail"
      :customer="stripeId"
      locale="ru"
      @loading="(v) => (loading = v)"
    />
    <stripe-checkout
      v-else
      ref="checkoutRef"
      mode="payment"
      :pk="publishableKey"
      :line-items="lineItems"
      :success-url="successURL"
      :cancel-url="cancelURL"
      :clientReferenceId="uid"
      :customerEmail="userEmail"
      :customer="stripeId"
      locale="ru"
      @loading="(v) => (loading = v)"
    />
    <v-img
      :src="imageUrl"
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,.5), rgba(0,0,0,.5)"
      height="200px"
    >
      <v-btn
        absolute
        top
        class="ma-2"
        color="primary"
        outlined
        label
        v-if="type == 'guide'"
      >
        Электронная книга
      </v-btn>
      <v-btn
        absolute
        top
        class="ma-2"
        color="primary"
        outlined
        label
        v-if="userSubscribed && type == 'marathon'"
      >
        Доступно до
        {{ endDateDisplayShort }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-card-title>{{ name }}</v-card-title>
      <!-- <v-card-subtitle>{{startDateDisplay}}</v-card-subtitle> -->
    </v-img>
    <v-card-actions class="pa-0" v-if="userSubscribed">
      <v-btn
        color="primary"
        block
        dark
        large
        :to="
          type === 'marathon' && !successUrlProp
            ? `${type}/${productId}`
            : undefined
        "
        :href="
          type === 'marathon' && successUrlProp
            ? successUrlProp
            : type !== 'marathon'
            ? downloadURL
            : undefined
        "
        :download="type !== 'marathon'"
      >
        Продолжить
      </v-btn>
    </v-card-actions>
    <v-card-actions class="pa-0" v-else-if="productAvailable">
      <v-btn color="secondary" block dark large @click="buyNow"> Купить </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  mdiBookOpenBlankVariant,
  mdiBookOpenOutline,
  mdiBookOpenPageVariant,
  mdiBookOpenPageVariantOutline,
  mdiBookOpenVariant,
  mdiStarOutline,
  mdiCheckCircleOutline,
  mdiAccountOutline,
  mdiDotsHorizontal,
  mdiTimerSand,
  mdiMapMarkerOutline,
  mdiLockOutline,
} from "@mdi/js";
import { computed, ref } from "@vue/composition-api";
import {
  getStorage,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import store from "@/store";
import moment from "moment";
moment.locale("ru");

export default {
  components: {
    StripeCheckout,
  },
  props: {
    productId: {
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    startDate: {
      default: "",
    },
    imagePath: {
      type: String,
      default: "",
    },
    subscription: {
      default: false,
    },
    downloadPath: {
      default: "",
    },
    prices: {},
    successUrl: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const imageUrl = "";
    const downloadURL = "";
    const uatVars = {
      publishableKey:
        "pk_test_51JzbJLKXna6DHxM4VtCGdIn5XY1Rolh5q0ZbtAspKkmQfXc8mzSzltYEvOnX6kPhhON5E8sfxjNDeWNw493eWtPd00rDeYY4Ka",
      lineItems: [
        {
          price: props.prices.usd.stripeIdUAT, // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: props.successUrl
        ? props.successUrl
        : "http://localhost:8080/marathon",
      cancelURL: "http://localhost:8080/marathon",
    };
    const publishableKey =
      "pk_live_51JzbJLKXna6DHxM4QpycJfeBPaInA709nqknAAig6sn6tEvIccO6KwkpCDER1K0awBEmiL2sAaxrWUuJmVyU2sND005qx1wRnC";
    const lineItems = [
      {
        price: props.prices.usd.stripeId, // The id of the one-time price you created in your Stripe dashboard
        quantity: 1,
      },
    ];
    const successUrlProp = props.successUrl;
    const successURL = props.successUrl
      ? `https://app.tanyagundi.com/payment-redirect?successUrl=${encodeURIComponent(
          props.successUrl
        )}`
      : "https://app.tanyagundi.com/marathon";
    const cancelURL = "https://app.tanyagundi.com/";
    const checkoutRef = ref(null);
    const startDateDisplay = computed(() => {
      const startDateConverted = moment.unix(props.startDate);
      return startDateConverted.format("MMMM, DD YYYY");
    });

    const environment = computed(() => {
      return process.env.NODE_ENV;
    });

    const stripeId = computed(() => {
      if (store.state.user.user.stripeId !== "")
        return store.state.user.user.stripeId;
      return;
    });
    const stripeIdUAT = computed(() => {
      if (store.state.user.user.stripeIdUAT !== "")
        return store.state.user.user.stripeIdUAT;
      return;
    });
    const userEmail = computed(() => {
      if (store.state.user.user.email !== "")
        return store.state.user.user.email;
      return;
    });
    const uid = computed(() => {
      if (store.state.user.user.uid !== "") return store.state.user.user.uid;
      return;
    });

    const startDateDisplayShort = computed(() => {
      const startDateConverted = moment.unix(props.startDate);
      return startDateConverted.format("MMMM, DD");
    });

    const endDateDisplayShort = computed(() => {
      if (props.subscription) {
        const endDateConverted = moment.unix(
          store.state.user.user.subscriptions[props.productId].expiry.seconds
        );
        return endDateConverted.format("MMMM, DD");
      }
    });

    const productAvailable = computed(() => {
      const todayDate = moment();
      const startDateConverted = moment.unix(props.startDate);
      if (todayDate > startDateConverted) {
        return true;
      } else {
        return false;
      }
    });

    const userSubscribed = computed(() => {
      return props.subscription;
    });

    const buyNow = () => {
      localStorage.removeItem("userData");
      checkoutRef.value.redirectToCheckout();
    };

    return {
      environment,
      imageUrl,
      downloadURL,
      startDateDisplay,
      stripeId,
      stripeIdUAT,
      uid,
      userEmail,
      startDateDisplayShort,
      endDateDisplayShort,
      userSubscribed,
      productAvailable,
      checkoutRef,
      buyNow,
      publishableKey,
      uatVars,
      lineItems,
      successUrlProp,
      successURL,
      cancelURL,
      icons: {
        mdiBookOpenBlankVariant,
        mdiBookOpenOutline,
        mdiBookOpenPageVariant,
        mdiBookOpenPageVariantOutline,
        mdiBookOpenVariant,
        mdiStarOutline,
        mdiCheckCircleOutline,
        mdiAccountOutline,
        mdiDotsHorizontal,
        mdiLockOutline,
        mdiTimerSand,
      },
    };
  },
  mounted() {
    if (this.$route.query.buy == this.productId) {
      if (!this.userSubscribed) {
        this.buyNow();
      }
    }
    const storage = getStorage();
    getDownloadURL(storageRef(storage, this.imagePath))
      .then((url) => {
        // `url` is the download URL for 'images/stars.jpg'
        this.imageUrl = url;
        getDownloadURL(storageRef(storage, this.downloadPath))
          .then((url) => {
            this.downloadURL = url;
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        // Handle any errors
      });
  },
};
</script>
<style lang="scss" scoped>
.slider-card {
  background: none !important;
  box-shadow: none !important;
}
.promo-badge {
  width: 3rem;
  height: 3rem;
  padding-top: 0.7rem;
  right: -1.3rem;
  box-sizing: border-box;
  top: -1rem;
  line-height: 1;
  z-index: 1;
  display: block;
  position: absolute;
  background: #37c2d2;
  color: #fff;
  font-size: 85%;
  border-radius: 100%;
  -webkit-transform: rotate(-15deg);
  transform: rotate(-15deg);
  -webkit-box-shadow: -2px -2px 2px rgb(0 0 0 / 20%);
  box-shadow: -2px -2px 2px rgb(0 0 0 / 20%);
}
</style>